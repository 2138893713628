import clsx from "clsx";
import NextImage, { ImageProps } from "next/image";

interface Props {
  isBackgroundImage?: boolean;
  url: string | StaticImageData; 
  width?: number;
  height?: number;
  className?: string;
  alt: string;
  sizes?: string;
  objectFit?: string;
  loading?: "lazy" | "eager";
  onClick?: () => void;
  blurDataURL?: string;
  placeholder?: "blur" | "empty";
  isIcon?: boolean;
}

export default function Image(props: Props) {
  const {
    isBackgroundImage = false,
    url,
    width,
    height,
    className,
    alt: _alt,
    sizes,
    objectFit,
    loading,
    onClick,
    blurDataURL,
    placeholder,
    isIcon
  } = props;

  let alt = _alt;
  if (isIcon && !alt) alt = '';
  const imageProp: any = {
    src: url,
    alt,
    className: clsx(
      {
        clickable: !!onClick,
        "background-image": isBackgroundImage,
      },
      "image",
      className
    ),
    sizes,
  };
  if (!!onClick) {
    imageProp.onClick = onClick;
  }
  if ((isBackgroundImage && !width && !height) || !width || !height) {
    imageProp.layout = "fill";
    imageProp.objectFit = "cover";
    imageProp.objectPosition = "center";
  }
  if (width) imageProp.width = width;
  if (height) imageProp.height = height;
  if (objectFit) imageProp.objectFit = objectFit;
  if (loading) imageProp.loading = loading;
  // console.log('image', imageProp);

  return <NextImage {...imageProp} blurDataURL={blurDataURL} placeholder={placeholder} />;
}
