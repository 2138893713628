import axios from "axios";
import { MyBookMarkItem, MyEventItem, Profile, UserInfo } from "../types";
import Bugsnag from "./bugsnag";

export async function getUserInfo(idToken: string) {
  try {
    const res = await axios.get(
      `${process.env.NEXT_PUBLIC_API_SERVER_URL}/users/${idToken}?showDetail=true`
    );
    const { data } = res;
    return data as UserInfo;
  } catch (err) {
    Bugsnag.notify(err);
    return undefined;
  }
}

export async function getMyEvents(idToken: string) {
  try {
    const res = await axios.get(
      `${process.env.NEXT_PUBLIC_API_SERVER_URL}/users/${idToken}/events`
    );
    const { data } = res;
    return (data || []) as MyEventItem[];
  } catch (err) {
    Bugsnag.notify(err);
    return [];
  }
}

export async function getMyBookmarks(idToken: string) {
  try {
    const config = {
      params: { showEventDetail: true },
    };
    const res = await axios.get(
      `${process.env.NEXT_PUBLIC_API_SERVER_URL}/users/${idToken}/bookmarks`,
      config
    );
    const { data } = res;
    return (data || []) as MyBookMarkItem[];
  } catch (err) {
    Bugsnag.notify(err);
    return [];
  }
}

export async function getProfile(idToken: string) {
  try {
    const res = await axios.get(
      `${process.env.NEXT_PUBLIC_API_SERVER_URL}/users/${idToken}`
    );
    const { data } = res;
    return data as Profile;
  } catch (err) {
    Bugsnag.notify(err);
    throw err;
  }
}

export async function updateProfile(
  idToken: string,
  profile: Partial<Profile>
) {
  try {
    const res = await axios.post(
      `${process.env.NEXT_PUBLIC_API_SERVER_URL}/users/${idToken}`,
      profile
    );
    const { data } = res;
    return data;
  } catch (err) {
    Bugsnag.notify(err);
    throw err;
  }
}
