import Link from "next/link";
import { useTranslation } from 'next-i18next';
import Image from "../image";
import SocialMediaIconGroup from "./socialMediaIconGroup";

export interface Props {
  buildVersion: string;
}

export default function LayoutFooter({ buildVersion }: Props) {
  const { i18n: {language: locale}, t } = useTranslation();
  return (
    <footer className="layout-footer">
      <SocialMediaIconGroup className="layout-footer-social-media" />
      <div className="layout-footer-links">
        <Link href="/about" prefetch={false}>
          <a data-click-label="footer_about_us">{t("pageTitle.about")}</a>
        </Link>
        <a data-click-label="footer_contact_us" href="https://forms.gle/pZMNJ4biMHCxg1xV6" target="_blank">{t("footer.contact-us")}</a>
        <Link href="/privacy-policy" prefetch={false}>
          <a data-click-label="footer_privacy_policy">{t("footer.privacy-policy")}</a>
        </Link>
        <Link href="/terms-of-use" prefetch={false}>
          <a data-click-label="footer_term_of_use">{t("footer.terms-of-use")}</a>
        </Link>
        <a data-click-label="footer_feature_request" href="https://forms.gle/wNzcGkJjGCHs4gQt9" target="_blank">{t("pageTitle.featureRequest")}</a>
      </div>
      <small>
        {t("copyrightStatement", { year: new Date().getFullYear() })}
        {buildVersion && (
          <span style={{ color: "#343b42", marginLeft: "48px" }}>
            BUILD: {buildVersion}{" "}
          </span>
        )}
      </small>
      <button
        type="button"
        className="go-up-btn"
        data-click-label="go_to_top"
        onClick={() => {
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
        }}
        aria-label="go to top"
      >
        <Image
          className="svg-icon"
          width={20}
          height={20}
          url="/icons/chevron-up-outline.svg"
          alt=""
        />
      </button>
    </footer>
  );
}
